import { useState, useContext, useEffect } from 'react'
import { AuthContext } from '../context/AuthProvider'
import { useParams } from 'react-router-dom';

const AutoLogin = () => {
    const { otp } = useParams();

    const { loginOTP } = useContext(AuthContext);

    useEffect(() => {
        loginOTP(otp);

    }, [])

    return (
        <main id="home">
            <section className="section-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7">
                            <h1 className="mb-5">Please wait...</h1>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default AutoLogin;