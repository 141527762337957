import { useEffect, useState, createContext } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import useToastNotification from '../hooks/useToastNotification'

const baseURL = process.env.REACT_APP_BASE_URL

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {

  const [authTokens, setAuthTokens] = useState(() => localStorage.getItem('referAuth') ? JSON.parse(localStorage.getItem('referAuth')).authToken : {})
  const [authUser, setAuthUser] = useState(() => localStorage.getItem('referAuth') ? JSON.parse(localStorage.getItem('referAuth')).authUser : {})

  const [loading, setLoading] = useState(true)
  const [disableSubmit, setDisableSubmit] = useState(false)

  const navigate = useNavigate()
  const location = useLocation()
  const { notifyPromise, notifyError } = useToastNotification()


  // This is for axios refresh token
  useEffect(()=> {

    if(authTokens){
        setAuthTokens(authTokens)
    }
    
    setLoading(false)


  }, [authTokens])


  const login = async (user) => {
    setDisableSubmit(true)

    try {

      const urlencoded = new URLSearchParams();
      urlencoded.append('grant_type', 'password');
      urlencoded.append('username', user.eid);
      urlencoded.append('password', user.pwd);

      const authTokenResponse = await notifyPromise(fetch(`${baseURL}/token`, {
          method:'POST',
          headers:{
              'Content-Type':'application/x-www-form-urlencoded'
          },
          body: urlencoded
      }), 'Logging in...', null, null)


      const authTokenData = await authTokenResponse.json()

      if(authTokenResponse.status === 200){
    
        const authUserResponse = await notifyPromise(fetch(`${baseURL}/microrefer/getuserdetails?username=${user.eid}&password=${user.pwd}`, {
          method:'GET',
          headers:{
            'Authorization': `Bearer ${authTokenData.access_token}`
          }
        }), 'Fetching user details', 'Login success!', null)

        const authUserData = await authUserResponse.json()
        
        if (authUserResponse.status === 200) {
          setAuthTokens(authTokenData)
          setAuthUser(authUserData)
    
          const storeLSData = {
            authToken: authTokenData,
            authUser: authUserData
          }
    
          localStorage.setItem('referAuth', JSON.stringify(storeLSData))
    
          setDisableSubmit(true)

          // This is mainly for JobDetails page because the page has no authentication process
          // If the location.state?.path has value, it will redirect to /JobDetails/ path
          const redirectPath = location.state?.path || '/Home'
          
          navigate(redirectPath, { replace: true })

        } else {
          notifyError(`User credentials not match, Login failed`)
          
        }
        
      } else {
        const errMsg = `Error ${authTokenResponse.status}: ${authTokenData.error_description}`
        setDisableSubmit(false)
        
        throw errMsg
      }

    } catch (err) {
      notifyError(err)
      console.error('Fetching token error')
    }
  }

  const loginOTP = async (otp) => {

    try {

      const urlencoded = new URLSearchParams();
      urlencoded.append('grant_type', 'password');
      urlencoded.append('microrefer_otpcode', otp);

      const authTokenResponse = await notifyPromise(fetch(`${baseURL}/token`, {
          method:'POST',
          headers:{
              'Content-Type':'application/x-www-form-urlencoded'
          },
          body: urlencoded
      }), 'Logging in...', null, null)


      const authTokenData = await authTokenResponse.json()

      if(authTokenResponse.status === 200){
    
        const authUserResponse = await notifyPromise(fetch(`${baseURL}/microrefer/getuserdetailsbyid?id=${authTokenData.userName}`, {
          method:'GET',
          headers:{
            'Authorization': `Bearer ${authTokenData.access_token}`
          }
        }), 'Fetching user details', 'Login success!', null)

        const authUserData = await authUserResponse.json()
        
        if (authUserResponse.status === 200) {
          setAuthTokens(authTokenData)
          setAuthUser(authUserData)
    
          const storeLSData = {
            authToken: authTokenData,
            authUser: authUserData
          }
    
          localStorage.setItem('referAuth', JSON.stringify(storeLSData))
    
          setDisableSubmit(true)

          // This is mainly for JobDetails page because the page has no authentication process
          // If the location.state?.path has value, it will redirect to /JobDetails/ path
          const redirectPath = location.state?.path || '/Home'
          
          navigate(redirectPath, { replace: true })

        } else {
          notifyError(`User credentials not match, Login failed`)
          
        }
        
      } else {
        const errMsg = `Error ${authTokenResponse.status}: ${authTokenData.error_description}`
        setDisableSubmit(false)
        
        throw errMsg
      }

    } catch (err) {
      notifyError(err)
      console.error('Fetching token error')
    }
  }

  const logout = () => {
    setDisableSubmit(false)

    setAuthTokens({})
    setAuthUser({})
    localStorage.removeItem('referAuth')
    navigate('/', { replace: true })
  }

  const contextData = {
    authTokens,
    authUser,
    disableSubmit,
    setAuthTokens,
    setAuthUser,
    login,
    loginOTP,
    logout,
  }

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  )
}